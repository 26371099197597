import React, { InputHTMLAttributes, ReactNode } from 'react';
import './styles';
import { Container, Input } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
    minHeight?: string;
    children?: ReactNode;
    label?: string;
    isInvalid?: boolean;
}

const InputComponent = React.forwardRef<HTMLInputElement, IInputProps>(({ name, minHeight, children, label, isInvalid, ...rest }, ref) => {

    return (
        <Container minHeight={minHeight}>
            {
                label !== undefined &&
                <label htmlFor={name}>{label}</label>
            }
            <div className={`input-space ${(isInvalid !== true) ? "" : "invalid"}`}>
                {
                    children !== undefined &&
                    <span className="icon">
                        {
                            (isInvalid !== undefined && isInvalid !== null && isInvalid !== false) ?
                                <i className="bi bi-exclamation-circle invalid"></i>
                                :
                                <>{children}</>
                        }
                    </span>
                }
                <Input name={name} ref={ref} withIcon={children !== undefined ? true : false} minHeight={minHeight} {...rest} />
            </div>
        </Container>
    );
});

export default InputComponent;
