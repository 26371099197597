import React from 'react';
import { Routes, Route } from "react-router-dom";
import PrivateRoute from './helpers/PrivateRoute';

import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { Concursos } from './pages/Concursos';

function App() {

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/session-expired" element={<Login />} />
      <Route path="/home" element={
        <PrivateRoute><Home /></PrivateRoute>
      } />
      <Route path="/concursos" element={
        <PrivateRoute><Concursos /></PrivateRoute>
      } />
    </Routes>
  );
}

export default App;
