import React from 'react';

interface IOrderArrowComponentProps {
    orderobject: { field: string, orderType: string };
    fieldname: string;
}

const OrderArrowComponent = ({ orderobject, fieldname }: IOrderArrowComponentProps) => {

    return (
        <>
            {
                orderobject.field === fieldname &&
                <>
                    {
                        orderobject.orderType === "ASC" ?
                            <>
                                <i className="bi bi-arrow-up-short"></i>
                            </>
                            :
                            <>
                                <i className="bi bi-arrow-down-short"></i>
                            </>
                    }
                </>
            }
        </>

    );
}

export default OrderArrowComponent;
