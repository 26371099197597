import React, { HTMLAttributes, ReactNode } from 'react';
import './styles';
import { Container, Spinner } from './styles';

interface ISpinnerComponentProps extends HTMLAttributes<HTMLDivElement> {
    variant?: string;
    children?: ReactNode;
}

const SpinnerComponent = ({ variant, children }: ISpinnerComponentProps) => {

    return (
        <Container>
            <Spinner>

            </Spinner>
        </Container>
    );
}

export { SpinnerComponent };
