import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { IDocument, TEvaluateReason, TEvaluateStatus } from '../..';
import { ButtonComponent } from '../../../ButtonComponent';
import { Container, DocReasons } from './styles';

interface IDocItemComponentProps {
    doc: IDocument;
    evaluatefunction: (doc_id: number, reason: TEvaluateReason, status: TEvaluateStatus) => void;
}

const DocItemComponent = ({ doc, evaluatefunction }: IDocItemComponentProps) => {

    const [docReasonsVisible, setDocReasonsVisible] = useState(false);
    const [docReasonsSelected, setDocReasonsSelected] = useState<TEvaluateReason>(doc.evaluate_item_reason_disapproved);

    function openArchive(url: string) {
        let tempUrl = document.createElement("a");
        tempUrl.href = url;
        tempUrl.target = "_blank";
        tempUrl.click();
    }

    useEffect(() => {
        if (docReasonsSelected && docReasonsSelected !== "Aprovado") {
            evaluatefunction(doc.id, docReasonsSelected, "Reprovado");
            setDocReasonsVisible(false);
        }
    }, [docReasonsSelected])

    return (
        <Container>
            <div className="doc-status">{doc.evaluate_item_status}</div>
            <div className="doc-label">Arquivo: {doc.label}</div>
            <div className="doc-identify">Identificador: {doc.archive}</div>
            <div className="doc-date">{dayjs(doc.date).format("DD/MM/YYYY")}</div>
            <div className="doc-opts">
                <ButtonComponent minWidth="150px" variant="primary" onClick={() => openArchive(doc.url)}><i className="bi bi-eye"></i>&nbsp;Visualizar</ButtonComponent>
                <ButtonComponent minWidth="150px" variant="success" onClick={() => { evaluatefunction(doc.id, null, "Aprovado"); setDocReasonsSelected("Aprovado") }}><i className="bi bi-check2-circle"></i>&nbsp;Aprovar</ButtonComponent>
                <ButtonComponent minWidth="150px" variant="danger" onClick={() => setDocReasonsVisible(!docReasonsVisible)}><i className="bi bi-x-circle"></i>&nbsp;Reprovar</ButtonComponent>
            </div>
            <DocReasons visible={docReasonsVisible}>
                <strong>Motivo:</strong>
                <Form.Check
                    type="radio"
                    id={`default-${doc.archive}`}
                    label={`Documento ilegível`}
                    name="doc_reason"
                    onChange={event => setDocReasonsSelected("Documento ilegível")}
                    checked={docReasonsSelected === "Documento ilegível"}
                />
                <Form.Check
                    type="radio"
                    id={`default-${doc.archive}`}
                    label={`Documento não condiz com as informações do candidato`}
                    name="doc_reason"
                    onChange={event => setDocReasonsSelected("Documento não condiz com as informações do candidato")}
                    checked={docReasonsSelected === "Documento não condiz com as informações do candidato"}
                />
            </DocReasons>
        </Container>

    );
}

export { DocItemComponent };
