import axios from "axios";
import { Cookies } from 'react-cookie';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(function (config) {

    const cookies = new Cookies();

    if (config.url !== '/session' && config.headers !== undefined) {

        // const token = cookies.get('https://avaliardocumentos.unileao.edu.br/session');
        const token = sessionStorage.getItem(String(process.env.REACT_APP_SESSION_KEY));

        config.headers['authorization'] = `Bearer ${token}`;

        return config;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const cookies = new Cookies();

    if (error.response.status === 401) {
        // cookies.remove('https://avaliardocumentos.unileao.edu.br/session');
        sessionStorage.removeItem(String(process.env.REACT_APP_SESSION_KEY));
        window.location.replace('/session-expired');
        alert("Sessão expirada, faça login novamente.");
    }

    return Promise.reject(error);
});

export { api };