import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { ButtonComponent } from '../../components/ButtonComponent';
import InputComponent from '../../components/InputComponent';
import { SpinnerComponent } from '../../components/SpinnerComponent';
import { AppContext } from '../../context/ContextProvider';
import { Container } from './styles';
import logo_docs from '../../assets/unileao/logo-docs.png';

interface ILoginInputs {
  user: string;
  password: string;
}

const Login: React.FC = () => {

  const theme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ILoginInputs>();

  const [isLoading, setIsLoading] = useState(false);

  const { handleLogin } = useContext(AppContext);

  async function onSubmit(data: ILoginInputs) {
    setIsLoading(true);
    await handleLogin(data.user, data.password);
    setIsLoading(false);
  }

  return (
    <Container>
      <div id="logo-docs">
        <img src={logo_docs} alt={`Logo Docs`} />
      </div>
      <div id="alternative-logo-representation">
        <img src="https://unileao.edu.br/wp-content/themes/portalv2.0/img/logo20anos.svg" alt={`Logo ${theme.general.ies_name}`} />
      </div>
      <div id="form">
        <div id="logo-representation">
          <img src="https://unileao.edu.br/wp-content/themes/portalv2.0/img/logo20anos2.svg" alt={`Logo ${theme.general.ies_name}`} />
        </div>
        <h1>Gestão de Documentos</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-box">
            <InputComponent label="Usuário:" type="text"
              {...register("user", {
                required: "Este campo é obrigatório.",
                maxLength: {
                  value: 255,
                  message: "O usuário deve conter no máximo 255 caracteres."
                }
              })}

              isInvalid={errors.user ? true : false}
            >
              <i className="bi bi-person-fill"></i>
            </InputComponent>
            {errors.user && <span className="label-error">{errors.user.message}</span>}
          </div>

          <div className="input-box">
            <InputComponent label="Senha:" type="password"
              {...register("password", {
                required: "Este campo é obrigatório.",
                maxLength: {
                  value: 255,
                  message: "A senha deve conter no máximo 255 caracteres."
                }
              })}

              isInvalid={errors.password ? true : false}
            >
              <i className="bi bi-lock-fill"></i>
            </InputComponent>
            {errors.password && <span className="label-error">{errors.password.message}</span>}
          </div>

          <ButtonComponent type="submit" variant="primary">
            {
              isLoading ?
                <SpinnerComponent />
                :
                <>
                  ENTRAR&nbsp;<i className="bi bi-chevron-right"></i>
                </>
            }
          </ButtonComponent>
        </form>
      </div>
    </Container>
  );
}

export { Login };
