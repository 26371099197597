import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Badge, FloatingLabel, Form, ListGroup, Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import Swal from 'sweetalert2';
import { ButtonComponent } from '../../components/ButtonComponent';
import { EvaluateModal } from '../../components/EvaluateModal';
import { FindCRMInscriptionsModal } from '../../components/FindCRMInscriptionsModal';
import { MainContainer } from '../../components/MainContainer';
import OrderArrowComponent from '../../components/OrderArrowComponent';
import { AppContext } from '../../context/ContextProvider';
import { api } from '../../services/ApiService';
import { OrderService } from '../../services/OrderService';
import { IConcurso } from '../Concursos';
import { Abstract, Container, Header, InscriptionsList } from './styles';

enum IInscricaoStatus {
  "Aprovado" = "Aprovado",
  "Reprovado" = "Reprovado",
  "Pendente de avaliação" = "Pendente de avaliação",
  "Documentação Pendente" = "Documentação Pendente"
}

export interface IInscricao {
  id: string;
  concurso: string;
  cod_concurso: string;
  oferta: string;
  turno: string;
  inscription_number: string;
  name: string;
  cpf: string;
  email: string;
  phone: string;
  status: IInscricaoStatus;
  data_criacao: string;
  data_nascimento: string;
  anexos_loaded: boolean;
  created_at: string;
}

const Home: React.FC = () => {

  const { user } = useContext(AppContext);
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [concursos, setConcursos] = useState<IConcurso[]>([]);
  const [inscriptions, setInscriptions] = useState<IInscricao[]>([]);
  const [filteredInscriptions, setFilteredInscriptions] = useState(0);

  const [concursoFilter, setConcursoFilter] = useState("");
  const [anoFilter, setAnoFilter] = useState("");
  const [semestreFilter, setSemestreFilter] = useState("");
  const [canFilter, setCanFilter] = useState("");
  const [nomeFilter, setNomeFilter] = useState("");
  const [cpfFilter, setCpfFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("Pendente de avaliação");
  const [orderedBy, setOrderedBy] = useState<{ field: keyof IInscricao, orderType: "ASC" | "DESC" }>({ field: "created_at", orderType: "DESC" });

  const [showEvaluateModal, setShowEvaluateModal] = useState(false);
  const [showFindCRMInscriptionsModal, setShowFindCRMInscriptionsModal] = useState(false);
  const [inscriptionToEvaluate, setInscriptionToEvaluate] = useState<IInscricao | null>(null);

  async function getInscriptions(concurso_id: string) {
    if (concurso_id === undefined || concurso_id === "") return;

    setIsLoading(true);
    try {
      const result = await api.get(`/inscription/${concurso_id}`);

      setInscriptionsOrder(orderedBy.field, orderedBy.orderType, result.data);

    } catch (error) {

    }
    setIsLoading(false);
  }

  async function getConcursos() {
    try {
      const result = await api.get("/concurso");

      setConcursos(result.data);

      if (result.data.length > 0) {
        setConcursoFilter(result.data[0].identificador)
      }

    } catch (err: AxiosError | any) {
      Swal.fire({
        toast: true,
        icon: "warning",
        title: "Atenção!",
        text: err.response ? err.response.data.error : "Falha ao tentar carregar concursos, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        confirmButtonText: "Aceitar"
      })
    }
  }

  const contadorDeInscricoesPorStatus = () => {
    var quant = 0;
    inscriptions.map(item => {
      if (item.status === statusFilter) {
        quant = quant + 1;
      }
    });
    return quant
  }

  useEffect(() => {
    getConcursos();
  }, [])

  useEffect(() => {
    let quantidade = contadorDeInscricoesPorStatus();

    setFilteredInscriptions(quantidade);
  }, [statusFilter, inscriptions])

  useEffect(() => {
    let concurso_fild = document.getElementById("concurso-filter");

    if (concurso_fild !== null) {
      let select_element: any = concurso_fild.attributes[0].ownerElement;

      if (select_element !== null && select_element["options"].length > 0) {
        let opt_selected = select_element[select_element.selectedIndex]

        setConcursoFilter(opt_selected.value)
      }
    }
  }, [anoFilter, semestreFilter])

  useEffect(() => {
    getInscriptions(concursoFilter);
  }, [concursoFilter])

  function handleEvaluate(inscription: IInscricao) {
    setInscriptionToEvaluate(inscription);
    setShowEvaluateModal(true);
  }

  function handleFindCRMInscriptions() {
    setShowFindCRMInscriptionsModal(true);
  }

  function setInscriptionsOrder(orderBy: keyof IInscricao, orderType: "ASC" | "DESC", data?: IInscricao[]) {
    const orderService = new OrderService();

    if (data === undefined && inscriptions.length <= 0) return;

    const setOrder = (inscri_a: IInscricao, inscri_b: IInscricao) => {
      let a = inscri_a[orderBy];
      let b = inscri_b[orderBy];

      if (typeof a === "string" && typeof b === "string") {
        let isDate = dayjs(a).isValid();

        if (isDate) {
          return orderService.dateOrder(dayjs(a), dayjs(b), orderType);
        } else if (Number.isNaN(a)) {
          return orderService.numberOrder(a, b, orderType);
        } else {
          return orderService.stringOrder(a, b, orderType);
        }
      } else if (typeof a === "boolean" && typeof b === "boolean") {
        return orderService.booleanOrder(a, b, orderType);
      } else {
        return orderService.stringOrder(String(a), String(b), orderType);
      }
    }

    if (data !== undefined) {
      let new_array = data.sort(setOrder);
      setInscriptions([...new_array]);
    } else {
      let new_array = inscriptions.sort(setOrder);
      setInscriptions([...new_array]);
    }
  }

  useEffect(() => {
    setInscriptionsOrder(orderedBy.field, orderedBy.orderType);
  }, [orderedBy])

  return (
    <MainContainer>
      <Container>
        <Header id="header">
          <div className="concurso-filter">
            <FloatingLabel label="Concurso">
              <Form.Select id="concurso-filter" placeholder="Concurso" onChange={(event) => {
                setConcursoFilter(event.target.value);
                // getInscriptions(event.target.value);
              }} value={concursoFilter}>
                {
                  concursos.length > 0 && concursos.filter(item => {
                    if (anoFilter !== "" && semestreFilter !== "") {
                      if (item.name.includes(anoFilter) && item.name.endsWith(semestreFilter))
                        return true
                    } else if (anoFilter !== "") {
                      if (item.name.includes(anoFilter))
                        return true
                    } else {
                      return true
                    }
                  }).map((item, idx) => {
                    return <option key={idx} value={item.identificador}>{item.name}</option>
                  })
                }
              </Form.Select>
            </FloatingLabel>
          </div>
          <div className="ano-filter">
            <FloatingLabel label="Ano">
              <Form.Control size="sm" id="ano-filter" type="text" placeholder="Ano" onChange={(event) => setAnoFilter(event.target.value)} value={anoFilter} />
            </FloatingLabel>
          </div>
          <div className="semestre-filter">
            <FloatingLabel label="Semestre">
              <Form.Select id="semestre-filter" placeholder="Semestre" onChange={(event) => setSemestreFilter(event.target.value)} value={semestreFilter}>
                <option>Selecione...</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </Form.Select>
            </FloatingLabel>
          </div>
          <div className="can-filter">
            <FloatingLabel label="CAN">
              <Form.Control size="sm" id="can-filter" type="text" placeholder="CAN" onChange={(event) => setCanFilter(event.target.value)} value={canFilter} />
            </FloatingLabel>
          </div>
          <div className="nome-filter">
            <FloatingLabel label="Nome">
              <Form.Control size="sm" id="nome-filter" type="text" placeholder="Nome" onChange={(event) => setNomeFilter(event.target.value)} value={nomeFilter} />
            </FloatingLabel>
          </div>
          <div className="cpf-filter">
            <FloatingLabel label="CPF">
              <Form.Control size="sm" id="cpf-filter" type="text" placeholder="CPF" onChange={(event) => {
                let value = event.target.value;

                value = value.replace(/\D/g, '');
                value = value.replace(/^(\d{3})(\d)/g, '$1.$2');
                value = value.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
                value = value.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4');
                value = value.replace(/^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/, '$1.$2.$3-$4');

                setCpfFilter(value.substring(0, 14));
              }} value={cpfFilter} />
            </FloatingLabel>
          </div>
          <div className="status-filter">
            <FloatingLabel label="Status">
              <Form.Select id="status-filter" placeholder="Status" onChange={(event) => setStatusFilter(event.target.value)} value={statusFilter}>
                <option value="Pendente de avaliação">Pendente de avaliação</option>
                <option value="Aprovado">Aprovado</option>
                <option value="Reprovado">Reprovado</option>
                <option value="Documentação Pendente">Documentação Pendente</option>
              </Form.Select>
            </FloatingLabel>
          </div>
          <div className="options">
            <ButtonComponent variant="primary" onClick={() => handleFindCRMInscriptions()}>
              <i className="bi bi-person-rolodex"></i>&nbsp;Importar do CRM
            </ButtonComponent>
            <ButtonComponent onClick={() => getInscriptions(concursoFilter)}>
              <i className="bi bi-arrow-clockwise"></i>&nbsp;Recarregar resultados
            </ButtonComponent>
          </div>
        </Header>

        <Abstract id="abstract">
          <p>Inscrições na situação <strong>{statusFilter}</strong> deste concurso: <strong>{filteredInscriptions}</strong></p>
        </Abstract>

        <InscriptionsList id="inscriptions-list">
          {
            isLoading ?
              <div className="isloading">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              </div>
              :
              <>
                <ListGroup as="ul">
                  <ListGroup.Item key={"ABC"} as="li" className="cabecalho-item">
                    <div className="cabecalho-list">
                      <div className="list-item-field" onClick={() => setOrderedBy({ field: "inscription_number", orderType: (orderedBy.orderType === "ASC" ? "DESC" : "ASC") })}>
                        Inscrição&nbsp;<OrderArrowComponent orderobject={orderedBy} fieldname="inscription_number" />
                      </div>
                      <div className="list-item-field" onClick={() => setOrderedBy({ field: "name", orderType: (orderedBy.orderType === "ASC" ? "DESC" : "ASC") })}>
                        Nome&nbsp;<OrderArrowComponent orderobject={orderedBy} fieldname="name" />
                      </div>
                      <div className="list-item-field" onClick={() => setOrderedBy({ field: "cpf", orderType: (orderedBy.orderType === "ASC" ? "DESC" : "ASC") })}>
                        CPF&nbsp;<OrderArrowComponent orderobject={orderedBy} fieldname="cpf" />
                      </div>
                      <div className="list-item-field" onClick={() => setOrderedBy({ field: "data_nascimento", orderType: (orderedBy.orderType === "ASC" ? "DESC" : "ASC") })}>
                        Idade&nbsp;<OrderArrowComponent orderobject={orderedBy} fieldname="data_nascimento" />
                      </div>
                      <div className="list-item-field" onClick={() => setOrderedBy({ field: "oferta", orderType: (orderedBy.orderType === "ASC" ? "DESC" : "ASC") })}>
                        Curso&nbsp;<OrderArrowComponent orderobject={orderedBy} fieldname="oferta" />
                      </div>
                      <div className="list-item-field" onClick={() => setOrderedBy({ field: "turno", orderType: (orderedBy.orderType === "ASC" ? "DESC" : "ASC") })}>
                        Turno&nbsp;<OrderArrowComponent orderobject={orderedBy} fieldname="turno" />
                      </div>
                      <div className="list-item-field" onClick={() => setOrderedBy({ field: "status", orderType: (orderedBy.orderType === "ASC" ? "DESC" : "ASC") })}>
                        Status&nbsp;<OrderArrowComponent orderobject={orderedBy} fieldname="status" />
                      </div>
                      <div className="list-item-field" onClick={() => setOrderedBy({ field: "anexos_loaded", orderType: (orderedBy.orderType === "ASC" ? "DESC" : "ASC") })}>
                        Anexos&nbsp;<OrderArrowComponent orderobject={orderedBy} fieldname="anexos_loaded" />
                      </div>
                      <div className="list-item-field" onClick={() => setOrderedBy({ field: "data_criacao", orderType: (orderedBy.orderType === "ASC" ? "DESC" : "ASC") })}>
                        Data de Inscrição&nbsp;<OrderArrowComponent orderobject={orderedBy} fieldname="data_criacao" />
                      </div>
                    </div>
                  </ListGroup.Item>
                  {
                    inscriptions.length > 0 &&
                    inscriptions.filter(item => {

                      let
                        name = true,
                        cpf = true,
                        status = true,
                        can = true;

                      if (nomeFilter.length > 0) {
                        name = item.name.toUpperCase().includes(nomeFilter.toUpperCase());
                      }
                      if (canFilter.length > 0) {
                        can = item.inscription_number.includes(canFilter);
                      }
                      if (cpfFilter.length > 0) {
                        cpf = item.cpf.includes(cpfFilter);
                      }
                      if (statusFilter.length > 0) {
                        status = item.status === statusFilter;
                      }

                      return (name && cpf && status && can);

                    }).map(item => {
                      return <ListGroup.Item key={item.inscription_number} as="li" className="list-item-custom" onClick={() => handleEvaluate(item)}>
                        <div className="list-item-field">
                          {item.inscription_number}
                        </div>
                        <div className="list-item-field">
                          {item.name}
                        </div>
                        <div className="list-item-field">
                          {item.cpf}
                        </div>
                        <div className="list-item-field">
                          {(() => {
                            if (dayjs(item.data_nascimento).isValid() === false || dayjs(dayjs(item.data_nascimento).format("YYYY-MM-DD")).isSame(dayjs("2021-10-27"))) {
                              return <>Não definido</>
                            } else {
                              return <>{dayjs().diff(dayjs(item.data_nascimento), "year") >= 18 ?
                                <Badge bg="primary">
                                  MAIOR
                                </Badge>
                                :
                                <Badge bg="warning" text="dark">
                                  MENOR
                                </Badge>}</>
                            }
                          })()}
                        </div>
                        <div className="list-item-field">
                          {item.oferta}
                        </div>
                        <div className="list-item-field">
                          {item.turno}
                        </div>
                        <div className="list-item-field">
                          {item.status}
                        </div>
                        <div className="list-item-field">
                          {item.anexos_loaded === true ? "🟢" : "🔴"}
                        </div>
                        <div className="list-item-field">
                          {dayjs(item.data_criacao).format("DD/MM/YYYY HH:mm")}
                        </div>
                      </ListGroup.Item>
                    })
                  }
                </ListGroup>
              </>
          }
        </InscriptionsList>

        <FindCRMInscriptionsModal
          show={showFindCRMInscriptionsModal}
          onHide={() => {
            setShowFindCRMInscriptionsModal(false);
            getInscriptions(concursoFilter);
          }}
          codConcursoTofind={concursoFilter}
        />

        <EvaluateModal
          show={showEvaluateModal}
          onHide={() => {
            setShowEvaluateModal(false);
            setInscriptionToEvaluate(null);
            getInscriptions(concursoFilter);
          }}
          inscriptionToEvaluate={inscriptionToEvaluate}
        />
      </Container>
    </MainContainer>
  );
}

export { Home };
