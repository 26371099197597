import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { AppContext } from '../../context/ContextProvider';
import './styles';
import { Container, SideBar } from './styles';

const SideBarComponent = () => {

    const { showSideBar, setShowSideBar, handleLogOut, user } = useContext(AppContext);
    const history = useNavigate();

    return (
        <Container isVisible={showSideBar}>
            <SideBar>
                <div className="close-button">
                    <button onClick={() => setShowSideBar(!showSideBar)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                    <span className="user-loged">Usuário: {user?.name}</span>
                </div>
                <div className="content">
                    <div onClick={handleLogOut} className="content-item"><span>Sair <i className="bi bi-box-arrow-right"></i></span></div>
                    <div onClick={() => history("/concursos")} className="content-item"><span>Concursos <i className="bi bi-journals"></i></span></div>
                    <div onClick={() => history("/home")} className="content-item"><span>Inscrições <i className="bi bi-people-fill"></i></span></div>
                </div>
            </SideBar>
        </Container>
    );
}

export { SideBarComponent };
