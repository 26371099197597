import styled from "styled-components";

export const Container = styled.div`
    background-color: #F5F5f5;

    flex-grow: 1;
    width: 100%;
    padding: 10px 20px;

    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    width: 100%;
   
    margin-bottom: 10px;
    
    background-color: #FFF;
    border-radius: 4px;
    padding: 10px;

    .concurso-filter{
        grid-area: concurso-filter;
    }
    .ano-filter{
        grid-area: ano-filter;
    }
    .semestre-filter{
        grid-area: semestre-filter;
    }
    .can-filter{
        grid-area: can-filter;
    }
    .nome-filter{
        grid-area: nome-filter;
    }
    .cpf-filter{
        grid-area: cpf-filter;
    }
    .status-filter{
        grid-area: status-filter;
    }
    .options{
        grid-area: options;

        display: flex;
        justify-content: flex-end;
        
        button{
            margin-left: 10px;
            max-width: 200px;
        }

        @media screen and (max-width: 464px){
            align-items: center;
            flex-direction: column;

            button{
                margin-bottom: 10px;
                margin-left: 0;
                width: 100%;
                max-width: initial;
            }
        }
    }

    .concurso-filter, .ano-filter, .semestre-filter, .can-filter, .cpf-filter,.status-filter, .nome-filter, .options{
        width: 100%;
        padding: 5px 10px;
    }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template: 
    "concurso-filter concurso-filter concurso-filter"
    "status-filter ano-filter semestre-filter"
    "can-filter nome-filter cpf-filter"
    "options options options"
    ;

    justify-items: center;
    align-items: center;

    @media screen and (max-width: 790px){
        grid-template-columns: 1fr 1fr;
        grid-template: 
        "concurso-filter concurso-filter"
        "status-filter status-filter"
        "ano-filter semestre-filter"
        "nome-filter nome-filter"
        "can-filter cpf-filter"
        "options options"
        ; 
    }

    @media screen and (max-width: 590px){
        grid-template-columns: 1fr 1fr;
        grid-template: 
        "concurso-filter concurso-filter"
        "status-filter status-filter"
        "ano-filter semestre-filter"
        "nome-filter nome-filter"
        "can-filter can-filter"
        "cpf-filter cpf-filter"
        "options options"
        ; 
    }
`;

export const Abstract = styled.div`
    width: 100%;
    margin-bottom: 10px;
    
    background-color: #FFF;
    border-radius: 4px;
    padding: 5px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    p{
        margin: 0;
        font-weight: 500;
        margin-left: 10px;
        height: 100%;
    }
`;

export const InscriptionsList = styled.div`
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    
    background-color: #FFF;
    border-radius: 4px;
    padding: 10px;

    overflow-x: auto;

    .isloading{
        width: 100%;
        height: 100%;
       
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cabecalho-item{
        background-image: ${props => props.theme.navBar.backgroundColor};
        color: #FFF;
    }

    .cabecalho-list{
        font-weight: 500;

        .list-item-field{
            cursor: pointer;
        }
    }

    .list-item-custom, .cabecalho-list{
        display: flex;
        flex-direction: row;
        div{
            flex: 1;
            padding: 0 10px;
        }
        transition: 0.3s;
    }

    .list-item-custom:hover{
        margin: 5px 0;
        border-left: 5px solid #42A5F5;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        cursor: pointer;
    }

    @media screen and (max-width: 1036px){
        box-shadow: rgb(0 0 0 / 35%) -20px 0px 36px -28px inset;
        
        ul{
            min-width: 949px;
        }
    }
`;