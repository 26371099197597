import styled from "styled-components";

interface ISideBarProps {
    isVisible: boolean;
}

export const Container = styled.div`
    background-image: ${props => props.theme.navBar.backgroundColor};
    box-shadow: rgba(0, 0, 0, 0.24) 3px 3px 8px;
    
    width: 100%;
    height: ${props => props.theme.mensures.nav_bar_height};
    position: relative;

    .ies-logo{
        grid-area: ies-logo;
        padding: 5px;
        img{
            min-height: 50px
        }
    }

    .button-logout{
        grid-area: button-logout;

        button{
            background-color: transparent;
            color: #FFF;
            width: 100px;

            border: 1px solid #FFF;

            :hover{
                background-color: #FFF;
                color: #000;
            }
        }
    }

    .content{
        grid-area: content;
    }

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto 4fr;
    grid-template-areas: 
    "ies-logo content button-logout";

    justify-items: center;
    align-items: center;

    @media screen and (max-width: 362px){
        .ies-logo{
            
        }
    }
`;