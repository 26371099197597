import styled from "styled-components";

interface IButtonProps {
    minHeight?: string;
    minWidth?: string;
}

export const BaseButton = styled.button<IButtonProps>`
    background-color: #90A4AE;
    color: #FFF;

    width: 100%;
    min-width: ${props => props.minWidth ? props.minWidth : "none"};
    min-height: ${props => props.minHeight ? props.minHeight : "40px"};

    border: none;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    cursor: pointer;
    transition: 0.2s;

    :hover{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
        background-color: #90A4AEee;
    }

    :disabled{
        background-color: #B0BEC5;
        color: #FFF;
        box-shadow: none;
        cursor: default;

        :hover{
            box-shadow: none;
            background-color: #B0BEC5 !important;
        }
    }
`;


export const ButtonWarning = styled(BaseButton)`
    background-color: ${props => props.theme.button_colors.warning};

    :hover{
        background-color: ${props => props.theme.button_colors.warning}ee !important;
    }
`;

export const ButtonPrimary = styled(BaseButton)`
    background-color: ${props => props.theme.button_colors.primary};

    :hover{
        background-color: ${props => props.theme.button_colors.primary}ee !important;
    }
`;

export const ButtonSuccess = styled(BaseButton)`
    background-color:  ${props => props.theme.button_colors.success};

    :hover{
        background-color: ${props => props.theme.button_colors.success}ee !important;
    }
`;

export const ButtonDanger = styled(BaseButton)`
    background-color:  ${props => props.theme.button_colors.danger};

    :hover{
        background-color: ${props => props.theme.button_colors.danger}ee !important;
    }
`;

export const ButtonDangerOutline = styled(BaseButton)`
    background-color:  transparent;
    border: 1px solid ${props => props.theme.button_colors.danger};
    color: ${props => props.theme.button_colors.danger};
    box-shadow: none;

    :hover{
        background-color: ${props => props.theme.button_colors.danger} !important;
        color: #FFF;
    }
`;