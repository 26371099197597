import styled from "styled-components";

interface IDocsPendentesProps {
    isVisible: boolean;
}

export const Main = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .isloading{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 10px;
    }

    #info-candidato{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        border-bottom: 1px solid rgb(207, 216, 220);

        padding: 10px;
        width: 100%;
    }

    #docs{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        .title{
            width: 100%;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 10px;
        }

        .handles{
            button{
                width: auto;
                padding: 0 10px;
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }

        .doc-list, .doc-item{
            width: 100%;
        }
    }

    #historico{
        width: 100%;
        margin-top: 10px;

        .title{
            width: 100%;
            font-weight: 500;
            font-size: 18px;
        }

        .historic-body-item{
            margin-bottom: 10px;
        }
    }
`;

export const Footer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

    button{
        max-width: 150px;
        margin-right: 10px;
    }

    @media screen and (max-width: 991px){
        button{
            margin-bottom: 10px;
        }
    }
`;

export const DocsPendentes = styled.div<IDocsPendentesProps>`
    width: 100%;
    height: 100%;

    display: ${props => props.isVisible ? "flex" : "none"};
    flex-direction:  column;
    justify-content: flex-end;
    margin-top: 10px;

    .buttons{
        width: auto;
        align-self: flex-end;
        margin-top: 10px;

        .accept-button{
            width: auto !important;
            padding: 0 20px;
        }   
    }
`;