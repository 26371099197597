import styled from "styled-components";

interface ISideBarProps {
    isVisible: boolean;
}

export const Container = styled.div<ISideBarProps>`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 200;

    display: ${props => props.isVisible === true ? "flex" : "none"};

    background-color: rgb(33, 33, 33, 0.2);
`;

export const SideBar = styled.div`
    background-image: ${props => props.theme.navBar.backgroundColor};
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);

    width: 100%;
    max-width: 350px;
    margin-left: 10px;
    height: 100vh;
    padding: 10px;

    display: flex;
    flex-direction: column;

    transition: 0.5s;

    position: absolute;
    right: 0;

    animation: show;
    animation-duration: 0.5s;
    animation-timing-function: ease-in;
    
    @keyframes show {
        0%{
            width: 0;
        }
        100%{
            width: 100%;
        }
    }

    .close-button{
        width: 100%;
        display: flex;
        align-items: flex-start;
        height: auto;
        padding: 10px;

        button{
            height: 30px;
            border: none;
            background-color: transparent;
            cursor: pointer;
        }

        svg{
            color: #FFF;
        }

        .user-loged{
            display: flex;
            justify-content: flex-start;
            align-items: center;

            width: 100%;
            height: 100%;
            margin-left: 20px;

            color: #FFF;
            font-size: 14px;

            overflow-x: hidden;
        }
    }

    .content{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        height: 100%;
        overflow-y: auto;

        padding: 10px 10px;
        border-radius: 4px;
        background-color: #F5F5F5;

        .content-item{
            margin-bottom: 10px;
            width: 100%;
            height: 40px;
            transition: 0.3s;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            padding: 5px;
            border-radius: 4px;
            background-color: #FFF;
        }

        .content-item:hover{
            margin-bottom: 10px;
            width: 100%;

            border-left: 3px solid #0D47A1;
            background-color: #42A5F5;
            color: #FFF;

            cursor: pointer;
        }
    }
`;