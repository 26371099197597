import styled from "styled-components";

export const Main = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .accordion{
        width: 100%;
    }

    .isloading{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 10px;
    }

    .inscription-head{
        width: 100%;

        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        gap: 10px;

        padding: 0 10px;
    }

    .inscription-data{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        width: 100%;

        .attribute{
            width: 100%;
            margin-bottom: 10px;

            label{
                font-weight: 500;
            }
        }
    }

    .anexos-check{
        width: 100%;
        margin-top: 20px;
    }
`;

export const Footer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

    button{
        max-width: 150px;
        margin-right: 10px;
    }

    @media screen and (max-width: 991px){
        button{
            margin-bottom: 10px;
        }
    }
`;