import { AxiosError } from 'axios';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Swal from 'sweetalert2';
import { api } from '../services/ApiService';
import { Cookies } from 'react-cookie';

interface IUser {
    name: string;
    role: string;
}

interface IAppContext {
    user: IUser | null;
    handleLogin: (user: string, password: string) => Promise<void>;
    handleLogOut: () => void;
    showSideBar: boolean;
    setShowSideBar: (value: boolean) => void;
}

export const AppContext = createContext({} as IAppContext);

interface IContextProviderProps {
    children: ReactNode;
}

const ContextProvider = (props: IContextProviderProps) => {

    const [user, setUser] = useState<IUser | null>(null);
    const [showSideBar, setShowSideBar] = useState(false);
    const theme = useTheme();
    const navigator = useNavigate();
    const cookies = new Cookies();

    async function handleLogin(user: string, password: string) {
        try {
            const result = await api.post("/session", {
                user,
                password
            });

            setUser({
                name: result.data.user.name,
                role: result.data.user.role
            });

            // cookies.set("https://avaliardocumentos.unileao.edu.br/session", result.data.token.token, {
            //     expires: new Date(result.data.token.expiresIn)
            // })

            sessionStorage.setItem(String(process.env.REACT_APP_SESSION_KEY), result.data.token.token);

            navigator("/home");

        } catch (err: AxiosError | any) {

            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Atenção!",
                text: err.response ? err.response.data.error : "Falha ao tentar realizar login, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar"
            })
        }
    }

    function handleLogOut() {
        setUser(null);
        setShowSideBar(false);
        sessionStorage.removeItem(String(process.env.REACT_APP_SESSION_KEY));
        navigator("/", { replace: true });
    }

    useEffect(() => {
        if (window.location.pathname === "/session-expired") {
            sessionStorage.removeItem(String(process.env.REACT_APP_SESSION_KEY));
            setUser(null);
        }
    }, [])

    return (
        <AppContext.Provider value={
            {
                user,
                handleLogin,
                handleLogOut,
                showSideBar,
                setShowSideBar
            } as IAppContext
        }>
            {props.children}
        </AppContext.Provider>
    );
}

export default ContextProvider;