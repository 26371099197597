import { Dayjs } from "dayjs";


class OrderService {
    stringOrder(a: string, b: string, orderType: "ASC" | "DESC") {
        a = (a.toLocaleUpperCase()).normalize("NFD")
        b = (b.toLocaleUpperCase()).normalize("NFD")

        if (orderType === "ASC") {
            if (a > b) return 1
            if (a < b) return -1
            return 0
        } else {
            if (a < b) return 1
            if (a > b) return -1
            return 0
        }
    }

    numberOrder(a: string, b: string, orderType: "ASC" | "DESC") {
        if (orderType === "ASC") {
            if (Number(a) > Number(b)) return 1
            if (Number(a) < Number(b)) return -1
            return 0
        } else {
            if (Number(a) < Number(b)) return 1
            if (Number(a) > Number(b)) return -1
            return 0
        }
    }

    booleanOrder(a: boolean, b: boolean, orderType: "ASC" | "DESC" = "ASC") {
        if (orderType === "ASC") {
            if (a === true && b === false) return -1
            if (a === false && b === true) return 1
            if (a === b) return 0
        } else {
            if (a === true && b === false) return 1
            if (a === false && b === true) return -1
            if (a === b) return 0
        }
        return 0
    }

    dateOrder(a: Dayjs, b: Dayjs, orderType: "ASC" | "DESC") {
        if (orderType === "ASC") {
            if (a.isAfter(b)) return 1
            if (a.isBefore(b)) return -1
            return 0
        } else {
            if (a.isBefore(b)) return 1
            if (a.isAfter(b)) return -1
            return 0
        }
    }
}

export { OrderService };