import styled from "styled-components";

interface IInputProps {
    minHeight?: string;
    withIcon: boolean;
}

interface IContainerProps {
    minHeight?: string;
}

export const Container = styled.div<IContainerProps>`
    background-color: transparent;

    width: 100%;
    min-height: ${props => props.minHeight ? props.minHeight : "40px"};

    label{
        font-weight: 500;
        margin-bottom: 5px;
    }

    .invalid{
        input, .icon{
            border-color: #D50000 !important;

            :focus{
                box-shadow: rgba(239, 83, 80, 0.25) 0px 1px 3px, rgb(239, 83, 80, 0.5) 0px 0px 0px 3px;
            }
        }
        i{
            color: #D50000;
        }
    }

    .icon{
        background-color: #F5F5F5;
        border: 1px solid #BDBDBD;
        border-right: none;
        border-radius: 4px 0px 0px 4px;

        display: flex;
        justify-content: center;
        align-items: center;
        min-width: ${props => props.minHeight ? props.minHeight : "40px"}; /* Para ficar proporcional a altura do input*/
    }

    .input-space{
        display: flex;
        flex-direction: row;
        min-height: ${props => props.minHeight ? props.minHeight : "40px"};
    }
`;

export const Input = styled.input<IInputProps>`
    background-color: #F5F5F5;
    border: 1px solid #BDBDBD;
    border-radius: ${props => props.withIcon === true ? "0px 4px 4px 0px" : "4px"};
    border-left: ${props => props.withIcon === true ? "0px" : "1px"};

    width: 100%;
    min-height: ${props => props.minHeight ? props.minHeight : "40px"};
    outline: none;

    padding-left: 5px;

    :focus{
        box-shadow: rgba(41, 182, 246, 0.25) 0px 1px 3px, rgb(41, 182, 246, 0.5) 0px 0px 0px 3px;
    }
`;