import React, { useContext } from 'react';
import { useLocation, Navigate } from "react-router-dom";
import { AppContext } from '../context/ContextProvider';
import { Cookies } from 'react-cookie';

function PrivateRoute({ children }: { children: JSX.Element }) {

  let location = useLocation();

  const { user } = useContext(AppContext);
  const cookies = new Cookies();

  // const token = cookies.get("https://avaliardocumentos.unileao.edu.br/session");
  const token = sessionStorage.getItem(String(process.env.REACT_APP_SESSION_KEY));

  if (!user || !token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;

}

export default PrivateRoute;
