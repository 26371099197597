import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { FloatingLabel, Form, ListGroup, Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import Swal from 'sweetalert2';
import { ButtonComponent } from '../../components/ButtonComponent';
import { MainContainer } from '../../components/MainContainer';
import { SpinnerComponent } from '../../components/SpinnerComponent';
import { AppContext } from '../../context/ContextProvider';
import { api } from '../../services/ApiService';
import { Container, Header, ConcursosList } from './styles';

export interface IConcurso {
  id: string;
  name: string;
  identificador: string;
  identificador_totvs: string;
}

interface IStoreConcurso extends Omit<IConcurso, "id"> {
  concurso_id?: string;
}

const Concursos: React.FC = () => {

  const { user } = useContext(AppContext);
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCadastro, setIsLoadingCadastro] = useState(false);
  const [concursos, setConcursos] = useState<IConcurso[]>([]);

  const [nomeFilter, setNomeFilter] = useState("");
  const [identificadorFilter, setIdentificadorFilter] = useState("");
  const [identificadorTotvsFilter, setIdentificadorTotvsFilter] = useState("");

  const [concursoToEdit, setConcursoToEdit] = useState<IStoreConcurso | null>(null);

  async function getConcursos(reload: boolean = false) {

    if (reload) {
      setIsLoading(true);
    }

    try {
      const result = await api.get("/concurso");

      setConcursos(result.data);

    } catch (err: AxiosError | any) {
      Swal.fire({
        toast: true,
        icon: "warning",
        title: "Atenção!",
        text: err.response ? err.response.data.error : "Falha ao tentar carregar concursos, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        confirmButtonText: "Aceitar"
      })
    }

    setIsLoading(false);
  }

  async function saveConcurso() {
    setIsLoadingCadastro(true)
    try {
      await api.post("/concurso/store", {
        concurso_id: concursoToEdit !== null ? concursoToEdit.concurso_id : undefined,
        identificador: identificadorFilter,
        identificador_totvs: identificadorTotvsFilter,
        name: nomeFilter
      });

      Swal.fire({
        toast: true,
        icon: "success",
        title: "Feito!",
        text: `Concurso ${concursoToEdit === null ? "cadastrado" : "atualizado"} com sucesso.`,
        confirmButtonColor: theme.button_colors.primary,
        confirmButtonText: "Aceitar"
      })


      eraseFields();
      getConcursos();
    } catch (err: AxiosError | any) {
      Swal.fire({
        toast: true,
        icon: "warning",
        title: "Atenção!",
        text: err.response ? err.response.data.error : "Falha ao tentar carregar concursos, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        confirmButtonText: "Aceitar"
      })
    }

    setIsLoadingCadastro(false)
  }

  async function deleteConcurso(concurso_id: string) {
    try {
      await api.delete(`/concurso/delete/${concurso_id}`);

      Swal.fire({
        toast: true,
        icon: "success",
        title: "Feito!",
        text: `Concurso apagado com sucesso.`,
        confirmButtonColor: theme.button_colors.primary,
        confirmButtonText: "Aceitar"
      })

      getConcursos(true);
    } catch (err: AxiosError | any) {
      Swal.fire({
        toast: true,
        icon: "warning",
        title: "Atenção!",
        text: err.response ? err.response.data.error : "Falha ao tentar carregar concursos, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
        confirmButtonColor: theme.button_colors.primary,
        confirmButtonText: "Aceitar"
      })
    }
  }

  useEffect(() => {
    getConcursos();
  }, [])

  function handleEditConcurso(concurso: IConcurso) {
    setConcursoToEdit({
      concurso_id: concurso.id,
      identificador: concurso.identificador,
      identificador_totvs: concurso.identificador_totvs,
      name: concurso.name
    })

    setNomeFilter(concurso.name);
    setIdentificadorFilter(concurso.identificador);
    setIdentificadorTotvsFilter(concurso.identificador_totvs);
  }

  function eraseFields() {
    setConcursoToEdit(null);
    setNomeFilter("");
    setIdentificadorFilter("");
    setIdentificadorTotvsFilter("");
  }

  return (
    <MainContainer>
      <Container>
        <Header id="header">
          <div className="name-filter">
            <FloatingLabel label="Nome">
              <Form.Control size="sm" id="name-filter" type="text" placeholder="Nome" onChange={(event) => setNomeFilter(event.target.value)} value={nomeFilter} />
            </FloatingLabel>
          </div>
          <div className="identificador-filter">
            <FloatingLabel label="Identificador">
              <Form.Control size="sm" id="identificador-filter" type="text" placeholder="Identificador" onChange={(event) => setIdentificadorFilter(event.target.value)} value={identificadorFilter} />
            </FloatingLabel>
          </div>
          <div className="identificador-totvs-filter">
            <FloatingLabel label="Identificador Totvs">
              <Form.Control size="sm" id="identificador-totvs-filter" type="text" placeholder="Identificador Totvs" onChange={(event) => setIdentificadorTotvsFilter(event.target.value)} value={identificadorTotvsFilter} />
            </FloatingLabel>
          </div>
          <div className="options">
            <ButtonComponent onClick={() => getConcursos()}>
              <i className="bi bi-arrow-clockwise"></i>&nbsp;Recarregar resultados
            </ButtonComponent>
            <ButtonComponent onClick={() => eraseFields()}>
              Limpar Campos&nbsp;<i className="bi bi-eraser-fill"></i>
            </ButtonComponent>
            {
              concursoToEdit !== null ?
                <ButtonComponent variant="warning" onClick={() => saveConcurso()}>
                  {
                    isLoadingCadastro ?
                      <SpinnerComponent />
                      :
                      <>
                        Salvar Alterações&nbsp;<i className="bi bi-check2-circle"></i>
                      </>
                  }
                </ButtonComponent>
                :
                <ButtonComponent variant="success" onClick={() => saveConcurso()}>
                  {
                    isLoadingCadastro ?
                      <SpinnerComponent />
                      :
                      <>
                        Cadastrar&nbsp;<i className="bi bi-plus-circle-dotted"></i>
                      </>
                  }

                </ButtonComponent>
            }
          </div>
        </Header>

        <ConcursosList id="concursos-list">
          {
            isLoading ?
              <div className="isloading">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              </div>
              :
              <>
                <ListGroup as="ul">
                  <ListGroup.Item key={"ABC"} as="li" className="cabecalho-item">
                    <div className="cabecalho-list">
                      <div className="list-item-field">
                        Nome
                      </div>
                      <div className="list-item-field">
                        Identificador
                      </div>
                      <div className="list-item-field">
                        Identificador Totvs
                      </div>
                      <div className="list-item-field-opts">
                        Opções
                      </div>
                    </div>
                  </ListGroup.Item>
                  {
                    concursos.length > 0 &&
                    concursos.filter(item => {

                      let
                        name = true,
                        identificador = true,
                        identificador_totvs = true;

                      if (nomeFilter.length > 0) {
                        name = item.name.toUpperCase().includes(nomeFilter.toUpperCase());
                      }
                      if (identificadorFilter.length > 0) {
                        identificador = item.identificador.includes(identificadorFilter);
                      }
                      if (identificadorTotvsFilter.length > 0) {
                        identificador_totvs = item.identificador_totvs.includes(identificadorTotvsFilter);
                      }

                      return (name && identificador_totvs && identificador);

                    }).map(item => {
                      return <ListGroup.Item key={item.id} as="li" className="list-item-custom">
                        <div className="list-item-field">
                          {item.name}
                        </div>
                        <div className="list-item-field">
                          {item.identificador}
                        </div>
                        <div className="list-item-field">
                          {item.identificador_totvs}
                        </div>
                        <div className="list-item-field-opts">
                          <ButtonComponent variant="warning" onClick={() => handleEditConcurso(item)}>
                            <i className="bi bi-pencil-square"></i>
                          </ButtonComponent>
                          <ButtonComponent variant="danger" onClick={() => deleteConcurso(item.id)}>
                            <i className="bi bi-trash-fill"></i>
                          </ButtonComponent>
                        </div>
                      </ListGroup.Item>
                    })
                  }
                </ListGroup>
              </>
          }
        </ConcursosList>
      </Container>
    </MainContainer>
  );
}

export { Concursos };
