import React, { useContext, useState } from 'react';
import { useTheme } from 'styled-components';
import { AppContext } from '../../context/ContextProvider';
import { ButtonComponent } from '../ButtonComponent';
import './styles';
import { Container } from './styles';

const NavBar = () => {

    const { handleLogOut, showSideBar, setShowSideBar } = useContext(AppContext);
    const theme = useTheme();

    return (
        <Container>
            <div className="ies-logo">
                <img src="https://unileao.edu.br/wp-content/themes/portalv2.0/img/logo20anos.svg" alt={`Logo ${theme.general.ies_name}`} />
            </div>
            <div className="content">

            </div>
            <div className="button-logout">
                {/* <ButtonComponent type="button" onClick={handleLogOut}>Sair <i className="bi bi-box-arrow-right"></i></ButtonComponent> */}
                <ButtonComponent type="button" onClick={() => setShowSideBar(!showSideBar)}>Menu&nbsp;<i className="bi bi-list"></i></ButtonComponent>
            </div>
        </Container>
    );
}

export { NavBar };
