import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Spinner = styled.div`
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: -0.125em;
    border: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-radius: 50%;
    animation: spinner-router .75s linear infinite;

    @keyframes spinner-router {
        100% {
            transform: rotate(1turn);
        }
    }
`;


