import ies_logo from '../../assets/unileao/logo-branca.png';

export const unileao = {
    theme_name: "unileao",
    general: {
        system_name: "Gestão de Documentos",
        ies_name: "Unileão",
        ies_logo
    },
    mensures: {
        nav_bar_height: "61px"
    },
    colors: {
        background: '#F5F5F5',
        text_secondary: '#263238',
        text_primary: '#212121'
    },
    navBar: {
        backgroundColor: 'linear-gradient(-45deg, #0D47A1, #2196F3)'
    },
    button_colors: {
        success: '#00796B',
        warning: '#FBC02D',
        danger: '#D32F2F',
        primary: '#0D47A1'
    },
    login: {
        backgroundColor: 'linear-gradient(-45deg, #0D47A1, #2196F3)'
    }
}