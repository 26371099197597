import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, FloatingLabel, Form, ListGroup, Modal, Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import Swal from 'sweetalert2';
import { AppContext } from '../../context/ContextProvider';
import { IInscricao } from '../../pages/Home';
import { api } from '../../services/ApiService';
import { ButtonComponent } from '../ButtonComponent';
import { SpinnerComponent } from '../SpinnerComponent';
import { DocItemComponent } from './components/DocItem';
import { DocsPendentes, Footer, Main } from './styles';

interface IEvaluateModalProps {
    onHide: () => void;
    show: boolean;
    inscriptionToEvaluate: IInscricao | null;
}

interface IRequestAnexosReturn {
    archive: string;
    date: string;
    label: string;
    url: string;
}

export type TEvaluateStatus = "Aprovado" | "Reprovado" | "Não Avaliado";
export type TEvaluateReason = "Documento ilegível" | "Documento não condiz com as informações do candidato" | "Aprovado" | null;

export interface IDocument extends IRequestAnexosReturn {
    id: number;
    archive: string;
    date: string;
    label: string;
    url: string;
    evaluate_item_status: TEvaluateStatus;
    evaluate_item_reason_disapproved: TEvaluateReason;
}

enum EEvaluateStatus {
    "Aprovado" = "Aprovado",
    "Reprovado" = "Reprovado",
    "Documentação Pendente" = "Documentação Pendente"
}

enum EEvaluateItenStatus {
    "Aprovado" = "Aprovado",
    "Reprovado" = "Reprovado"
}

interface IUser {
    id: string;
    user_name: string;
    name: string;
    role: string;
    status: "Active" | "Inactive";
    created_at: Date;
    updated_at: Date;
}

interface IEvaluateItem {
    id: string;
    evaluate_id: string;
    identifier: string;
    reason_disapproved: string;
    status: EEvaluateItenStatus;
    created_at: Date;
    updated_at: Date;
}

interface IEvaluate {
    id: string;
    inscription_id: string;
    docs_pendentes: string;
    created_user_id: string;
    status: EEvaluateStatus
    items: IEvaluateItem[]
    created_at: Date;
    updated_at: Date;
    evaluator: IUser;
}

const EvaluateModal = ({ inscriptionToEvaluate, ...props }: IEvaluateModalProps) => {

    const { user } = useContext(AppContext);
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDocsPending, setIsLoadingDocsPending] = useState(false);
    const [isDocsPendingVisible, setIsDocsPendingVisible] = useState(false);
    const [isSendAvaliationLoading, setIsSendAvaliationLoading] = useState(false);
    const [isUpdateAnexosLoading, setIsUpdateAnexosLoading] = useState(false);
    const [isDownloadAnexosLoading, setIsDownloadAnexosLoading] = useState(false);

    const [documents, setDocuments] = useState<IDocument[]>([]);
    const [historic, setHistoric] = useState<IEvaluate[]>([]);
    const [docsPendentes, setDocsPendentes] = useState("");

    async function getHistoricoAndDocuments() {
        try {
            const result_historic = await api.get(`/inscription/${inscriptionToEvaluate?.id}/historic`);
            const result_anexos = await api.get(`/inscription/${inscriptionToEvaluate?.id}/anexos`);

            const anexos = result_anexos.data.map((item: IRequestAnexosReturn, idx: number) => {
                return {
                    id: idx,
                    ...item,
                    evaluate_item_status: "Não Avaliado",
                    evaluate_item_reason_disapproved: null
                }
            });

            setHistoric(result_historic.data);
            setDocuments(anexos);
        } catch (err: AxiosError | any) {
            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Atenção!",
                text: err.response ? err.response.data.error : "Falha ao carregar anexos e/ou historico de avaliação, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar"
            })
        }
        setIsLoading(false);
    }

    async function sendNotificationDocsPending(inscription_number: string) {
        if (docsPendentes !== "") {
            setIsLoadingDocsPending(true);
            try {
                await api.post(`/inscription/${inscription_number}/notification`, {
                    docsPendentesDescricao: docsPendentes
                });

                Swal.fire({
                    toast: true,
                    icon: "success",
                    title: "Feito!",
                    text: "Avaliação enviada com sucesso.",
                    confirmButtonColor: theme.button_colors.primary,
                    confirmButtonText: "Aceitar",
                    preConfirm: () => cancelModal()
                })

            } catch (err: AxiosError | any) {
                Swal.fire({
                    toast: true,
                    icon: "warning",
                    title: "Atenção!",
                    text: err.response ? err.response.data.error : "Falha ao tentar enviar avaliação, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
                    confirmButtonColor: theme.button_colors.primary,
                    confirmButtonText: "Aceitar"
                })
            }
            setIsLoadingDocsPending(false);
        } else {
            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Atenção!",
                text: "Você deve descrever os documentos pendentes para enviar a avaliação.",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar"
            })
        }
    }

    async function sendEvaluate(inscription_id: string) {
        if (documents.every(element => element.evaluate_item_status !== "Não Avaliado")) {
            setIsSendAvaliationLoading(true);
            try {
                await api.post(`/inscription/${inscription_id}/evaluate`, {
                    evaluate: documents
                });

                Swal.fire({
                    toast: true,
                    icon: "success",
                    title: "Feito!",
                    text: "Avaliação enviada com sucesso.",
                    confirmButtonColor: theme.button_colors.primary,
                    confirmButtonText: "Aceitar"
                })

            } catch (err: AxiosError | any) {
                Swal.fire({
                    toast: true,
                    icon: "warning",
                    title: "Atenção!",
                    text: err.response ? err.response.data.error : "Falha ao tentar enviar avaliação, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
                    confirmButtonColor: theme.button_colors.primary,
                    confirmButtonText: "Aceitar"
                })
            }
            setIsSendAvaliationLoading(false);
        } else {
            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Atenção!",
                text: "Você deve avaliar todos os itens antes de enviar a avaliação",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar"
            })
        }
    }

    async function updateAnexos(inscription_id: string) {
        setIsUpdateAnexosLoading(true);
        try {
            await api.post(`/inscription/${inscription_id}/anexos/update`);

            Swal.fire({
                toast: true,
                icon: "success",
                title: "Feito!",
                text: "Anexos atualizados com sucesso.",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar",
                preConfirm: () => getHistoricoAndDocuments()
            })

        } catch (err: AxiosError | any) {
            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Atenção!",
                text: err.response ? err.response.data.error : "Falha ao tentar atualziar anexos, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar"
            })
        }
        setIsUpdateAnexosLoading(false);
    }

    async function downloadAnexos(inscription_id: string) {
        setIsDownloadAnexosLoading(true);
        try {
            const archive = await api.post(`/inscription/${inscription_id}/anexos/download`);

            handleDownload(archive.data.file, archive.data.filename);

        } catch (err: AxiosError | any) {
            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Atenção!",
                text: err.response ? err.response.data.error : "Falha ao fazer downlaod dos anexos, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar"
            })
        }
        setIsDownloadAnexosLoading(false);
    }

    useEffect(() => {
        if (props.show === true && inscriptionToEvaluate !== null) {
            setIsLoading(true);
            getHistoricoAndDocuments();
        }

        if (props.show === false) {
            setIsLoading(false);
        }
    }, [props.show])

    function cancelModal() {
        setDocuments([]);
        setIsSendAvaliationLoading(false);
        setIsDocsPendingVisible(false);
        props.onHide();
    }

    function handleDownload(file: string, filename: string) {
        let tempURL = document.createElement('a');
        tempURL.href = file
        tempURL.target = "_blank";
        tempURL.download = filename;
        tempURL.click();
    }

    function handleEvaluate(doc_id: number, reason: TEvaluateReason, status: TEvaluateStatus) {
        const docFound = documents.find(element => element.id === doc_id);

        if (docFound) {
            let idx_of_doc = documents.indexOf(docFound);

            docFound.evaluate_item_reason_disapproved = reason;
            docFound.evaluate_item_status = status;

            documents[idx_of_doc] = docFound;
        } else {
            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Ops...",
                text: "Documento inválido.",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar"
            })
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Avaliar Documentos
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Main>
                    <div id="info-candidato">
                        <div><strong>Nome Completo:&nbsp;</strong>{inscriptionToEvaluate?.name}</div>
                        <div><strong>CPF:&nbsp;</strong>{inscriptionToEvaluate?.cpf}</div>
                        <div><strong>CAN:&nbsp;</strong>{inscriptionToEvaluate?.inscription_number}</div>
                        <div><strong>Curso:&nbsp;</strong>{inscriptionToEvaluate?.oferta}</div>
                        <div><strong>Turno:&nbsp;</strong>{inscriptionToEvaluate?.turno}</div>
                        {
                            inscriptionToEvaluate !== null &&
                            <div><strong>Data Nascimento:&nbsp;</strong>
                                {(() => {
                                    if (dayjs(inscriptionToEvaluate.data_nascimento).isValid() === false || dayjs(dayjs(inscriptionToEvaluate.data_nascimento).format("YYYY-MM-DD")).isSame(dayjs("2021-10-27"))) {
                                        return <>Não definido</>
                                    } else {
                                        return <>{dayjs(inscriptionToEvaluate.data_nascimento).format("DD/MM/YYYY")}</>
                                    }
                                })()}
                            </div>
                        }
                    </div>
                    {
                        isLoading ?
                            <div className="isloading">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Carregando...</span>
                                </Spinner>
                            </div>
                            :
                            <>
                                <div id="docs">
                                    <div className="title">Anexos( {documents.length} ):</div>
                                    <div className="handles">
                                        <ButtonComponent onClick={() => updateAnexos(String(inscriptionToEvaluate?.id))}>
                                            {
                                                isUpdateAnexosLoading ?
                                                    <>
                                                        <Spinner animation="border" role="status" size="sm" />
                                                    </>
                                                    :
                                                    <>
                                                        <i className="bi bi-arrow-clockwise"></i>&nbsp;Atualizar Anexos
                                                    </>
                                            }
                                        </ButtonComponent>
                                        <ButtonComponent onClick={() => downloadAnexos(String(inscriptionToEvaluate?.id))}>
                                            {
                                                isDownloadAnexosLoading ?
                                                    <>
                                                        <Spinner animation="border" role="status" size="sm" />
                                                    </>
                                                    :
                                                    <>
                                                        <i className="bi bi-download"></i>&nbsp;Baixar
                                                    </>
                                            }
                                        </ButtonComponent>
                                    </div>
                                    <ListGroup className="doc-list">
                                        {
                                            documents.length > 0 &&
                                            documents.map((item, idx) => {
                                                return <ListGroup.Item key={idx} className="doc-item">
                                                    <DocItemComponent evaluatefunction={handleEvaluate} key={idx} doc={item} />
                                                </ListGroup.Item>
                                            })
                                        }
                                    </ListGroup>
                                </div>
                                <div id="historico">
                                    <div className="title">Histórico( {historic.length} ):</div>
                                    <Accordion>
                                        {
                                            historic.length > 0 &&
                                            historic.map(item => (
                                                <Accordion.Item eventKey={item.id}>
                                                    <Accordion.Header>
                                                        <div className="historic-header">
                                                            <div className="evaluator">
                                                                <strong>Avaliador:</strong> {item.evaluator.name}
                                                            </div>
                                                            <div className="date">
                                                                <strong>Data:</strong> {dayjs(item.created_at).format("DD/MM/YYYY")}
                                                            </div>
                                                            <div className="docs-pendentes">
                                                                <strong>Docs Pendentes:</strong> {item.docs_pendentes}
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="historic-body">
                                                            {
                                                                item.items.length > 0 &&
                                                                item.items.map((item, idx) => (
                                                                    <div className="historic-body-item" key={idx}>
                                                                        <div><strong>Documento:</strong>  {item.identifier}</div>
                                                                        <div><strong>Status:</strong>  {item.status}</div>
                                                                        <div><strong>Motivo:</strong>  {item.reason_disapproved}</div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))
                                        }
                                    </Accordion>
                                </div>
                            </>
                    }

                    <DocsPendentes isVisible={isDocsPendingVisible}>
                        <div className="input">
                            <FloatingLabel label="Descreva os documentos aqui...">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Descreva os documentos aqui..."
                                    style={{ height: '100px' }}
                                    onChange={event => setDocsPendentes(event.target.value)}
                                />
                            </FloatingLabel>
                        </div>
                        <div className="buttons">
                            <ButtonComponent className="accept-button" variant="warning" onClick={() => sendNotificationDocsPending(String(inscriptionToEvaluate?.inscription_number))}>
                                {
                                    isLoadingDocsPending ?
                                        <SpinnerComponent />
                                        :
                                        <>
                                            Confirmar&nbsp;<i className="bi bi-check2-circle"></i>
                                        </>
                                }
                            </ButtonComponent>
                        </div>
                    </DocsPendentes>
                </Main>
            </Modal.Body>
            <Modal.Footer>
                <Footer>
                    <ButtonComponent variant="warning" onClick={() => setIsDocsPendingVisible(true)}>Docs. Pendentes</ButtonComponent>
                    <ButtonComponent variant="danger-outline" onClick={() => cancelModal()}>Cancelar</ButtonComponent>
                    {
                        inscriptionToEvaluate !== null &&
                        <ButtonComponent variant="success" onClick={() => sendEvaluate(inscriptionToEvaluate.id)} disabled={isDocsPendingVisible}>
                            {
                                isSendAvaliationLoading ?
                                    <SpinnerComponent />
                                    :
                                    <>
                                        Enviar&nbsp;<i className="bi bi-send"></i>
                                    </>
                            }
                        </ButtonComponent>
                    }
                </Footer>
            </Modal.Footer>
        </Modal>
    );
}

export { EvaluateModal };
