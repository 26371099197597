import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, FloatingLabel, Form, ListGroup, Modal, Spinner } from 'react-bootstrap';
import { useTheme } from 'styled-components';
import Swal from 'sweetalert2';
import { AppContext } from '../../context/ContextProvider';
import { api } from '../../services/ApiService';
import { ButtonComponent } from '../ButtonComponent';
import { SpinnerComponent } from '../SpinnerComponent';
import { Main, Footer } from './styles';

interface IFindCRMInscriptionsModalProps {
    onHide: () => void;
    show: boolean;
    codConcursoTofind: string;
}

interface IImportResult {
    inscription_number: string;
    cpf: string;
    nome: string;
    concurso: string;
    codigo_concurso: string;
    oferta: string;
    turno: string;
    qtd_anexos: number;
    email: string;
    celular: string;
    situacao: string;
    data_nascimento: string;
    data_inscricao: string;
}

interface IImportResultArrayItem extends IImportResult {
    importada: boolean;
}

interface IInscriptionDataToSend {
    NumeroInscricao: string;
    CreatedOn: string;
    Concurso: {
        Nome: string;
        Codigo: string;
    }
    Oferta: {
        Nome: string;
        TurnoOfertado: string;
    }
    Interessado: {
        DataNascimento: string;
        NomeCompleto: string;
        CPF: string;
        Email: string;
        TelefoneCelular: string;
    }
}

const FindCRMInscriptionsModal = ({ codConcursoTofind, ...props }: IFindCRMInscriptionsModalProps) => {

    const { user } = useContext(AppContext);
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [isImportingLoading, setIsImportingLoading] = useState(false);

    const [inscriptions, setInscriptions] = useState<IImportResultArrayItem[]>([]);
    const [forceDownload, setForceDownload] = useState(true);

    async function getInscriptions() {
        try {
            const result = await api.post(`/inscriptions/crm/v2`, {
                cod_concurso: codConcursoTofind
            });

            let inscriptionsToArray: IImportResultArrayItem[] = result.data.map((item: IImportResult) => ({
                ...item,
                importada: false
            }))

            setInscriptions(inscriptionsToArray);
        } catch (err: AxiosError | any) {
            Swal.fire({
                toast: true,
                icon: "warning",
                title: "Atenção!",
                text: err.response ? err.response.data.error : "Falha ao carregar anexos e/ou historico de avaliação, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
                confirmButtonColor: theme.button_colors.primary,
                confirmButtonText: "Aceitar"
            })
        }
        setIsLoading(false);
    }

    async function importInscrition() {
        setIsImportingLoading(true);

        for await (const [key, inscription] of inscriptions.entries()) {

            if (inscription.importada === true) continue;

            let inscription_data: IInscriptionDataToSend = {
                NumeroInscricao: inscription.inscription_number,
                CreatedOn: inscription.data_inscricao,
                Concurso: {
                    Codigo: inscription.codigo_concurso,
                    Nome: inscription.concurso
                },
                Interessado: {
                    CPF: inscription.cpf,
                    DataNascimento: inscription.data_nascimento,
                    Email: inscription.email,
                    NomeCompleto: inscription.nome,
                    TelefoneCelular: inscription.celular
                },
                Oferta: {
                    Nome: inscription.oferta,
                    TurnoOfertado: inscription.turno
                }
            }

            try {
                await api.post(`/inscriptions/crm/import`, {
                    dados: inscription_data,
                    force_attatchments_download: forceDownload
                });

                inscriptions[key].importada = true;
            } catch (err: AxiosError | any) {
                Swal.fire({
                    toast: true,
                    icon: "warning",
                    title: "Atenção!",
                    text: err.response ? err.response.data.error : "Falha ao tentar conectar-se ao servidor, por favor tente novamente.\nSe o erro persistir contate o administrador do sistema.",
                    confirmButtonColor: theme.button_colors.primary,
                    confirmButtonText: "Aceitar"
                })

                return;
            }
        }

        Swal.fire({
            toast: true,
            icon: "success",
            title: "Feito!",
            text: forceDownload === true ? "Inscrições importadas com sucesso, o download dos anexos etá sendo feito em segundo plano." : "Inscrição importada com sucesso.",
            confirmButtonColor: theme.button_colors.primary,
            confirmButtonText: "Aceitar"
        })

        setIsImportingLoading(false);
    }

    useEffect(() => {
        if (props.show === true) {
            setIsLoading(true);
            getInscriptions();
        }
    }, [props.show])

    function cancelModal() {
        setInscriptions([]);
        setForceDownload(true);
        props.onHide();
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Importador
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Main>
                    {
                        isLoading ?
                            <div className="isloading">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Carregando...</span>
                                </Spinner>
                            </div>
                            :
                            <>
                                <Accordion>
                                    {
                                        inscriptions.length <= 0 ?
                                            <>Nenhuma informação encontrada.</>
                                            :
                                            inscriptions.map((item, idx) => (
                                                <>
                                                    <Accordion.Item eventKey={String(idx)}>
                                                        <Accordion.Header>
                                                            <div className="inscription-head">
                                                                <div className="candidado-nome">
                                                                    {item.nome}
                                                                </div>
                                                                <div className="candidado-importado">
                                                                    <strong>Importada:</strong> {item.importada === true ? "✅" : "🛑"}
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="inscription-data">
                                                                <div className="attribute">
                                                                    <label>N° de Inscrição:</label>
                                                                    <div>
                                                                        {
                                                                            item.inscription_number
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="attribute">
                                                                    <label>CPF:</label>
                                                                    <div>
                                                                        {
                                                                            item.cpf
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="attribute">
                                                                    <label>Concurso:</label>
                                                                    <div>
                                                                        {
                                                                            item.concurso
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="attribute">
                                                                    <label>Oferta:</label>
                                                                    <div>
                                                                        {
                                                                            item.oferta
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="attribute">
                                                                    <label>Turno:</label>
                                                                    <div>
                                                                        {
                                                                            item.turno
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="attribute">
                                                                    <label>Quantidade de Anexos:</label>
                                                                    <div>
                                                                        {
                                                                            item.qtd_anexos
                                                                        } Anexos
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </>
                                            ))
                                    }
                                </Accordion>
                                <div className="anexos-check">
                                    <Form.Check
                                        type="checkbox"
                                        id={`default-anexos-check`}
                                        label={`Colocar download de anexos em segundo plano?`}
                                        onChange={() => setForceDownload(!forceDownload)}
                                        checked={forceDownload}
                                    />
                                </div>
                            </>
                    }
                </Main>
            </Modal.Body>
            <Modal.Footer>
                <Footer>
                    <ButtonComponent variant="success" onClick={() => importInscrition()}>
                        {
                            isImportingLoading ?
                                <SpinnerComponent />
                                :
                                <>
                                    Importar&nbsp;<i className="bi bi-check2-circle"></i>
                                </>
                        }
                    </ButtonComponent>
                    <ButtonComponent onClick={() => cancelModal()}>Fechar</ButtonComponent>
                </Footer>
            </Modal.Footer>
        </Modal>
    );
}

export { FindCRMInscriptionsModal };
