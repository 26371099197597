import styled from "styled-components";

interface IDocReasonsProps {
    visible: boolean;
}

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .doc-status{
        align-self: flex-end;
    }

    .doc-label, .doc-date, .doc-identify{
        font-weight: 500;
        margin-top: 5px;
    }

    .doc-opts{
        display: flex;
        justify-content: flex-start;
        align-items: center;

        margin-top: 5px;

        button{
            margin: 0 10px;
        }

        @media screen and (max-width: 991px){
            flex-direction: column;

            button{
                margin: 5px 0;
            }
        }
    }
`;

export const DocReasons = styled.div<IDocReasonsProps>`
    display: ${props => props.visible ? "flex" : "none"};
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    background-color: rgb(236, 239, 241);

    height: 100px;
    width: 100%;
    margin-top: 8px;
    border-radius: 4px;
    padding: 5px;

    animation: show;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;

    @keyframes show {
        0%{
            height: 0;
        }
        100%{
            height: 100px;
        }
    }
`;