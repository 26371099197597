import styled from "styled-components";

export const Container = styled.div`
    background-image: ${props => props.theme.login.backgroundColor};

    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;

    .label-error{
        color: #D32F2F;
    }

    #logo-docs{
        position: absolute;
        transition: 0.5s;
        right: 14%;

        animation: ping;
        animation-duration: 1.2s;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;

        @keyframes ping {
            0%{
                margin-top: 0;
            }
            100%{
                margin-top: -30px;
            }
        }
    }

    #logo-representation, #alternative-logo-representation{
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;

        img{
            width:50%;
        }
    }

    #alternative-logo-representation{
        display: none;
        max-width: 400px;

        img{
            width:60%;
        }
    }
    
    #form{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        position: relative;
        right: 400px;

        width: 100%;
        max-width: 400px;
        height: 100vh;

        background-color: #FAFAFA;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        transition: 0.5s;

        h1{
            margin-bottom: 10px;
            text-align: center;
            font-size: 32px;
        }

        form{
            width: 100%;
            height: 400px;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            padding: 15px;
            margin-top: -100px;

            .input-box{
                width: 100%;
                margin-bottom: 10px;
            }
        }

        @media screen and (max-width: 1201px){
            position: static;
            right: 0;

            height: 400px;
            max-width: 340px;

            border-radius: 6px;
            
            #logo-representation{
               display: none;
            }

            #alternative-logo-representation{
                display: flex;
            }

            h1{
                font-size: 28px;
            }

            form{
                height: auto;
                margin-top: 0;
            }
        }

        @media screen and (max-width: 394px){
            h1{
                font-size: 22px;
            }
        }
    }

    @media screen and (max-width: 1201px){

        #logo-representation{
            display: none;
        }

        #alternative-logo-representation{
            display: flex;
        }

        #logo-docs{
            display: none;
        }
    }
`;